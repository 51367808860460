import React from "react"
import Layout from "../../components/layout"
import BackBtn from "../../components/BackBtn"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

export default function fietsreis({ data }) {
  return (
    <Layout>
      <main className="main-content main-documentaires">
        <div className="title-container">
          <h1 className="main-title">Charleroi</h1>
          <BackBtn path="../" text="Terug naar documentaires"></BackBtn>
        </div>
        <p className="main-description">
          In het voorjaar van 2023 ben ik met de werkgroep Autonoom van
          Stichting Statief naar Charleroi geweest. Oude leegstaande industriële
          gebouwen fotograferen en natuurlijk ook mensen. Het was boeiender en
          uitdagender dan ik had verwacht en een originele invalshoek is niet
          eenvoudig.
        </p>
        <div className="photo-carousel">
          <Carousel>
            {data.allFile.nodes.map(image => {
              return (
                <Carousel.Item key={image.id} interval={5000}>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={image.base.split("-").join(" ").split(".")[0]}
                  />
                  <Carousel.Caption>
                    <p className="banner-text">
                      {image.base.split("-").join(" ").split(".")[0]}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </main>
    </Layout>
  )
}

export const getImages = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "documentaires/charleroi" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        base
        id
      }
    }
  }
`
